import * as React from "react";

// tslint:disable-next-line:max-func-body-length
function SvgLogoDark(props) {
    return (
<svg width="1555px" height="378px" viewBox="0 0 1555 378" {...props}>
    <defs>
        <filter x="-37.0%" y="-206.4%" width="174.0%" height="512.8%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="30" dy="22" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="187" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.219259511 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-780.000000, -240.000000)">
            <g id="Group-3" transform="translate(805.000000, 297.000000)">
                <circle id="Oval" stroke="#00FF00" stroke-width="50" fill-rule="nonzero" cx="125.5" cy="125.5" r="125.5"></circle>
                <g id="Group" filter="url(#filter-1)" transform="translate(359.000000, 15.000000)" fill="#FFFFFF">
                    <g id="p1-link" transform="translate(1012.000000, 0.000000)">
                        <path d="M0.238461538,0.374339623 C10.3492308,0.269056604 20.5076923,0.304150943 30.6244231,0.362641509 C30.7138462,10.3586792 30.6542308,20.360566 30.6601923,30.3566038 C28.3709615,30.7367925 26.1473077,30.6256604 23.8461538,30.6490566 C15.9471154,30.69 8.05403846,30.7777358 0.155,30.8128302 C0.0715384615,22.0977358 0.160961538,13.3826415 0.113269231,4.66754717 C0.119230769,3.24622642 0.0655769231,1.78981132 0.238461538,0.374339623 Z" id="p1"></path>
                    </g>
                    <g id="p2-link" transform="translate(321.000000, 17.000000)">
                        <path d="M0.292115385,0.174226804 C10.3969231,-0.0116151203 20.5673077,0.127766323 30.6780769,0.110343643 C30.7734615,54.6433333 30.6780769,109.234399 30.7257692,163.773196 C30.7317308,165.364467 30.7138462,166.955739 30.6661538,168.552818 C20.5375,168.576048 10.3909615,168.686392 0.268269231,168.494742 C0.220576923,113.880447 0.268269231,59.2545361 0.244423077,4.64024055 C0.244423077,3.15350515 0.190769231,1.65515464 0.292115385,0.174226804 Z" id="p2"></path>
                    </g>
                    <g id="p3-link" transform="translate(0.000000, 30.000000)">
                        <path d="M0.0928838951,0.436567164 C9.98501873,0.378358209 19.8829588,0.372537313 29.7750936,0.436567164 C29.8679775,33.5865672 29.7460674,66.748209 29.8331461,99.898209 C61.6052434,99.9273134 93.3831461,99.9331343 125.155243,99.898209 C125.236517,66.748209 125.120412,33.5923881 125.213296,0.44238806 C135.09382,0.366716418 144.985955,0.372537313 154.866479,0.44238806 C154.883895,52.1319403 154.912921,103.844776 154.849064,155.534328 C144.98015,155.621642 135.09382,155.615821 125.224906,155.540149 C125.114607,146.989254 125.230712,138.432537 125.166854,129.881642 C122.525468,129.584776 119.918914,129.701194 117.265918,129.695373 C90.7533708,129.695373 64.2466292,129.695373 37.7340824,129.695373 C35.0810861,129.701194 32.4687266,129.584776 29.8215356,129.881642 C29.7518727,138.438358 29.8737828,146.995075 29.7634831,155.551791 C19.8829588,155.615821 9.99082397,155.61 0.110299625,155.551791 C0.0406367041,103.856418 0.0754681648,52.1319403 0.0928838951,0.436567164 Z" id="p3"></path>
                    </g>
                    <g id="p4-link" transform="translate(516.000000, 30.000000)" fill-rule="nonzero">
                        <path d="M0.33761194,0.523880597 C2.55537313,0.343432836 4.76731343,0.384179104 6.98507463,0.395820896 C31.4328358,0.407462687 55.8864179,0.384179104 80.3283582,0.407462687 C96.8655224,0.878955224 113.1,6.85119403 126.039851,17.1483582 C137.553582,26.2638806 146.407164,38.5808955 151.273433,52.4520896 C155.953433,65.6946269 156.826567,80.1653731 153.974328,93.9085075 C150.761194,109.409552 142.635224,123.769701 131.051642,134.55 C118.996567,145.953134 103.251045,153.240896 86.7604478,155.080299 C84.0246269,155.179254 81.3179104,155.61 78.5820896,155.592537 C55.1064179,155.580896 31.6249254,155.592537 8.14925373,155.586716 C5.48328358,155.563433 2.98029851,155.720597 0.343432836,155.394627 C0.33761194,103.769104 0.343432836,52.149403 0.33761194,0.523880597 Z M30.3501493,125.911791 C46.6253731,125.90597 62.9180597,125.923433 79.1874627,125.900149 C85.7301493,125.556716 92.1564179,124.21209 98.0995522,121.383134 C106.982239,117.291045 114.508657,110.399104 119.409851,101.941343 C123.740597,94.5837313 125.917612,85.9280597 125.952537,77.4179104 C125.678955,69.5131343 123.763881,61.7014925 119.85806,54.7862687 C114.962687,45.9908955 107.238358,38.7671642 98.0820896,34.5702985 C91.149403,31.2640299 83.3261194,29.9135821 75.6774627,30.0591045 C60.6304478,30.1056716 45.461194,29.9950746 30.4316418,30.1114925 C30.1231343,30.8973134 30.0823881,31.7122388 30.0707463,32.5504478 C30.0940299,61.2765672 30.0765672,90.0201493 30.0823881,118.746269 C30.1114925,121.214328 29.9019403,123.484478 30.3501493,125.911791 Z" id="p4"></path>
                    </g>
                    <g id="p5-link" transform="translate(837.000000, 30.000000)">
                        <path d="M0.186268657,0.454029851 C2.64850746,0.372537313 5.10492537,0.39 7.56716418,0.401641791 C53.5522388,0.401641791 99.5373134,0.401641791 145.522388,0.401641791 C148.805373,0.413283582 152.065075,0.343432836 155.34806,0.454029851 C155.685672,3.56238806 155.493582,6.72313433 155.534328,9.89552239 C155.505224,16.5895522 155.598358,23.289403 155.487761,29.9834328 C152.548209,30.1289552 149.626119,30.0532836 146.686567,30.0649254 C107.779701,30.0765672 68.861194,30.0358209 29.9485075,30.0823881 C29.8437313,40.8044776 29.9077612,51.5323881 29.9135821,62.2544776 C29.8670149,62.9180597 30.1813433,63.2265672 30.8507463,63.1858209 C57.8597015,63.2265672 85.0549254,63.1625373 112.05806,63.2149254 C112.151194,73.1104478 112.098806,83.0059701 112.087164,92.9014925 C87.7267164,92.9538806 63.3604478,92.9073134 39,92.9247761 C35.9673134,92.9364179 32.9579104,92.8549254 29.9252239,93.0062687 C29.8320896,113.845075 29.971791,134.695522 29.861194,155.540149 C19.9773134,155.61 10.081791,155.639104 0.197910448,155.522687 C0.145522388,103.838955 0.16880597,52.1377612 0.186268657,0.454029851 Z" id="p5"></path>
                    </g>
                    <g id="p6-link" transform="translate(170.000000, 52.000000)" fill-rule="nonzero">
                        <path d="M98.2861538,7.4916309 C100.924786,8.73154506 103.284444,10.4233906 105.905641,11.6748927 C106.02188,8.41287554 105.923077,5.15085837 105.981197,1.8888412 C115.931282,1.8251073 125.887179,1.85407725 135.837265,1.87145923 C135.860513,45.7030043 135.883761,89.5403433 135.825641,133.371888 C125.892991,133.464592 115.92547,133.481974 105.987009,133.3603 C105.911453,130.022961 106.027692,126.679828 105.905641,123.342489 C103.813333,124.362232 101.918632,125.706438 99.8611966,126.795708 C93.2413675,130.509657 85.9705983,132.890987 78.4905983,134.142489 C72.5740171,135.05794 66.4365812,135.046352 60.4851282,134.524893 C48.9948718,133.232833 37.8533333,129.043777 28.5135043,122.212661 C17.4358974,114.286481 8.92136752,102.912876 4.37641026,90.1023605 C0.650940171,79.5109442 -0.56957265,67.8766094 1.3774359,56.7984979 C3.66735043,42.2150215 11.0892308,28.4311159 21.9750427,18.4248927 C31.3264957,9.67017167 43.2003419,3.69656652 55.8297436,1.45429185 C70.0225641,-1.44849785 85.3835897,1.0776824 98.2861538,7.4916309 Z M61.8335043,30.2620172 C52.6622222,31.5946352 44.0721368,36.8034335 38.3705983,44.0459227 C34.1511111,49.3300429 31.4078632,55.8482833 30.5476923,62.5403433 C30.2687179,67.1813305 30.1001709,72.1583691 31.3264957,76.6776824 C33.3897436,85.1832618 38.5565812,93.0167382 45.7169231,98.1096567 C52.2147009,102.912876 60.514188,105.47382 68.5870085,105.3 C76.7295726,105.334764 84.9186325,102.403004 91.3350427,97.4317597 C98.6755556,91.794206 103.720342,83.4276824 105.34188,74.3369099 C106.713504,67.9345494 105.655726,61.6422747 103.557607,55.5296137 C100.082051,46.0274678 93.0553846,37.9390558 83.982906,33.3733906 C77.1654701,30.0360515 69.3076923,28.9004292 61.8335043,30.2620172 Z" id="p6"></path>
                    </g>
                    <g id="p7-link" transform="translate(366.000000, 52.000000)" fill-rule="nonzero">
                        <path d="M77.7669528,1.15300429 C94.8881974,3.70815451 110.781116,13.3435622 121.111803,27.2085837 C128.707725,37.2901288 133.510944,49.6950644 134.304721,62.3143777 C134.976824,67.0654506 134.420601,71.6832618 133.991845,76.4169528 C132.479614,87.9238197 127.995064,98.9845494 120.961159,108.220172 C113.203004,118.498712 102.501502,126.459657 90.4036481,130.886266 C80.1193133,134.73927 69.0933476,135.619957 58.2238197,134.351073 C46.2012876,132.746137 34.6712446,127.838627 25.2444206,120.184764 C14.8847639,111.939914 7.13819742,100.415665 3.19248927,87.7963519 C0.0869098712,77.9407725 -0.515665236,67.5 0.892274678,57.2909871 C3.04763948,42.9334764 9.99463519,29.3813305 20.4759657,19.3171674 C35.2448498,4.69892704 57.2793991,-2.34077253 77.7669528,1.15300429 Z M60.5587983,31.7800429 C52.9512876,33.4255365 45.911588,37.1858369 40.6042918,42.9160944 C35.4418455,48.4957082 32.0407725,55.6281116 31.067382,63.1776824 C30.8298283,66.2079399 30.8298283,69.4120172 31.073176,72.4422747 C32.3362661,81.9675966 37.5160944,90.9714592 45.198927,96.7596567 C50.0600858,100.502575 55.8946352,102.999785 61.9609442,103.92103 C65.4199571,104.251288 68.9948498,104.233906 72.4538627,103.95 C80.2293991,102.872318 87.5182403,99.1873391 93.0515021,93.6309013 C99.0830472,87.72103 102.936052,79.667382 103.729828,71.2603004 C104.471459,64.1626609 102.941845,56.8042918 99.5349785,50.5351931 C94.9924893,42.1049356 87.083691,35.5635193 77.8944206,32.8055794 C72.4190987,30.9920601 66.2253219,31.1311159 60.5587983,31.7800429 Z" id="p7"></path>
                    </g>
                    <g id="p8-link" transform="translate(687.000000, 52.000000)" fill-rule="nonzero">
                        <path d="M62.0015021,0.440343348 C71.2776824,-0.208583691 80.5886266,0.903862661 89.3896996,3.9167382 C102.582618,8.40128755 114.182189,17.0633047 122.299571,28.3847639 C130.474893,39.7236052 135.075322,53.7856223 134.837768,67.7896996 C134.849356,72.1873391 134.519099,76.5560086 133.499356,80.8435622 C100.236052,80.8783262 66.9553648,80.8030043 33.6920601,80.8841202 C37.7304721,91.6667382 47.1862661,100.050644 58.3396996,102.854936 C65.2403433,104.703219 72.7609442,104.407725 79.5283262,102.130687 C84.8124464,100.409871 89.3143777,97.3390558 93.4049356,93.6366953 C96.3135193,96.1512876 98.8976395,99.0309013 101.661373,101.707725 C106.053219,106.227039 110.746352,110.503004 114.941202,115.201931 C104.755365,125.509442 91.0641631,132.375322 76.7066524,134.339485 C65.8660944,135.637339 54.8401288,134.721888 44.567382,130.915236 C31.7858369,126.297425 20.5686695,117.728112 12.6830472,106.673176 C4.64098712,95.4444206 0.16223176,81.6025751 0.225965665,67.7896996 C0.0521459227,56.7927039 3.00708155,45.1583691 8.30858369,35.5229614 C15.2266094,22.7819742 26.298927,12.4281116 39.5092275,6.43712446 C46.6184549,3.09978541 54.2317597,1.33261803 62.0015021,0.440343348 Z M35.3201717,51.0566524 C52.1227468,51.1957082 68.9427039,51.0740343 85.751073,51.1145923 C90.47897,51.0740343 95.2184549,51.2130901 99.9463519,51.0392704 C96.6148069,43.6809013 89.8706009,37.7420601 82.5933476,34.427897 C75.7216738,31.2527897 68.0562232,30.6154506 60.6457082,31.8843348 C50.0137339,33.9817597 40.0480687,41.2416309 35.3201717,51.0566524 Z" id="p8"></path>
                    </g>
                    <g id="p9-link" transform="translate(1012.000000, 54.000000)">
                        <path d="M0.190769231,0.173684211 C10.3313462,0.0694736842 20.5076923,0.0926315789 30.6542308,0.167894737 C30.6780769,43.9189474 30.6661538,87.6757895 30.6601923,131.426842 C28.9909615,131.594737 27.3157692,131.617895 25.6346154,131.600526 C17.1453846,131.536842 8.64423077,131.664211 0.155,131.536842 C0.0775,89.0478947 0.155,46.5357895 0.113269231,4.04105263 C0.113269231,2.75578947 0.0834615385,1.45315789 0.190769231,0.173684211 Z" id="p9"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    );
}

export default SvgLogoDark;
